import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {UserProfileProvider} from "./UserProfileProvider";
import {BrowserRouter} from "react-router-dom";
import {APIProvider} from '@vis.gl/react-google-maps';
import {Auth0ProviderWithNavigate} from "./components/auth0-provider-with-navigate";
import App from "./App/App";

const API_KEY = ("AIzaSyCFJhMw3rXpVRXvhxjFTmELaqdHigbQ2nw");

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Auth0ProviderWithNavigate>
          <UserProfileProvider>
            <APIProvider apiKey={API_KEY} onLoad={() => console.log('Maps API has loaded.')}>
              <App/>
            </APIProvider>
          </UserProfileProvider>
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null);
