/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.GetNetworkConfigurationRequestProto = (function() {

    /**
     * Properties of a GetNetworkConfigurationRequestProto.
     * @exports IGetNetworkConfigurationRequestProto
     * @interface IGetNetworkConfigurationRequestProto
     */

    /**
     * Constructs a new GetNetworkConfigurationRequestProto.
     * @exports GetNetworkConfigurationRequestProto
     * @classdesc Represents a GetNetworkConfigurationRequestProto.
     * @implements IGetNetworkConfigurationRequestProto
     * @constructor
     * @param {IGetNetworkConfigurationRequestProto=} [properties] Properties to set
     */
    function GetNetworkConfigurationRequestProto(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new GetNetworkConfigurationRequestProto instance using the specified properties.
     * @function create
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {IGetNetworkConfigurationRequestProto=} [properties] Properties to set
     * @returns {GetNetworkConfigurationRequestProto} GetNetworkConfigurationRequestProto instance
     */
    GetNetworkConfigurationRequestProto.create = function create(properties) {
        return new GetNetworkConfigurationRequestProto(properties);
    };

    /**
     * Encodes the specified GetNetworkConfigurationRequestProto message. Does not implicitly {@link GetNetworkConfigurationRequestProto.verify|verify} messages.
     * @function encode
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {IGetNetworkConfigurationRequestProto} message GetNetworkConfigurationRequestProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetNetworkConfigurationRequestProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified GetNetworkConfigurationRequestProto message, length delimited. Does not implicitly {@link GetNetworkConfigurationRequestProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {IGetNetworkConfigurationRequestProto} message GetNetworkConfigurationRequestProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetNetworkConfigurationRequestProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetNetworkConfigurationRequestProto message from the specified reader or buffer.
     * @function decode
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetNetworkConfigurationRequestProto} GetNetworkConfigurationRequestProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetNetworkConfigurationRequestProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetNetworkConfigurationRequestProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetNetworkConfigurationRequestProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetNetworkConfigurationRequestProto} GetNetworkConfigurationRequestProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetNetworkConfigurationRequestProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetNetworkConfigurationRequestProto message.
     * @function verify
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetNetworkConfigurationRequestProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a GetNetworkConfigurationRequestProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetNetworkConfigurationRequestProto} GetNetworkConfigurationRequestProto
     */
    GetNetworkConfigurationRequestProto.fromObject = function fromObject(object) {
        if (object instanceof $root.GetNetworkConfigurationRequestProto)
            return object;
        return new $root.GetNetworkConfigurationRequestProto();
    };

    /**
     * Creates a plain object from a GetNetworkConfigurationRequestProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {GetNetworkConfigurationRequestProto} message GetNetworkConfigurationRequestProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetNetworkConfigurationRequestProto.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this GetNetworkConfigurationRequestProto to JSON.
     * @function toJSON
     * @memberof GetNetworkConfigurationRequestProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetNetworkConfigurationRequestProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetNetworkConfigurationRequestProto
     * @function getTypeUrl
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetNetworkConfigurationRequestProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetNetworkConfigurationRequestProto";
    };

    return GetNetworkConfigurationRequestProto;
})();

$root.GetNetworkConfigurationResponseProto = (function() {

    /**
     * Properties of a GetNetworkConfigurationResponseProto.
     * @exports IGetNetworkConfigurationResponseProto
     * @interface IGetNetworkConfigurationResponseProto
     * @property {boolean|null} [okay] GetNetworkConfigurationResponseProto okay
     * @property {string|null} [networkName] GetNetworkConfigurationResponseProto networkName
     * @property {string|null} [networkDescription] GetNetworkConfigurationResponseProto networkDescription
     * @property {string|null} [primaryColor] GetNetworkConfigurationResponseProto primaryColor
     * @property {string|null} [backgroundColor] GetNetworkConfigurationResponseProto backgroundColor
     * @property {string|null} [cardColor] GetNetworkConfigurationResponseProto cardColor
     */

    /**
     * Constructs a new GetNetworkConfigurationResponseProto.
     * @exports GetNetworkConfigurationResponseProto
     * @classdesc Represents a GetNetworkConfigurationResponseProto.
     * @implements IGetNetworkConfigurationResponseProto
     * @constructor
     * @param {IGetNetworkConfigurationResponseProto=} [properties] Properties to set
     */
    function GetNetworkConfigurationResponseProto(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetNetworkConfigurationResponseProto okay.
     * @member {boolean} okay
     * @memberof GetNetworkConfigurationResponseProto
     * @instance
     */
    GetNetworkConfigurationResponseProto.prototype.okay = false;

    /**
     * GetNetworkConfigurationResponseProto networkName.
     * @member {string} networkName
     * @memberof GetNetworkConfigurationResponseProto
     * @instance
     */
    GetNetworkConfigurationResponseProto.prototype.networkName = "";

    /**
     * GetNetworkConfigurationResponseProto networkDescription.
     * @member {string} networkDescription
     * @memberof GetNetworkConfigurationResponseProto
     * @instance
     */
    GetNetworkConfigurationResponseProto.prototype.networkDescription = "";

    /**
     * GetNetworkConfigurationResponseProto primaryColor.
     * @member {string} primaryColor
     * @memberof GetNetworkConfigurationResponseProto
     * @instance
     */
    GetNetworkConfigurationResponseProto.prototype.primaryColor = "";

    /**
     * GetNetworkConfigurationResponseProto backgroundColor.
     * @member {string} backgroundColor
     * @memberof GetNetworkConfigurationResponseProto
     * @instance
     */
    GetNetworkConfigurationResponseProto.prototype.backgroundColor = "";

    /**
     * GetNetworkConfigurationResponseProto cardColor.
     * @member {string} cardColor
     * @memberof GetNetworkConfigurationResponseProto
     * @instance
     */
    GetNetworkConfigurationResponseProto.prototype.cardColor = "";

    /**
     * Creates a new GetNetworkConfigurationResponseProto instance using the specified properties.
     * @function create
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {IGetNetworkConfigurationResponseProto=} [properties] Properties to set
     * @returns {GetNetworkConfigurationResponseProto} GetNetworkConfigurationResponseProto instance
     */
    GetNetworkConfigurationResponseProto.create = function create(properties) {
        return new GetNetworkConfigurationResponseProto(properties);
    };

    /**
     * Encodes the specified GetNetworkConfigurationResponseProto message. Does not implicitly {@link GetNetworkConfigurationResponseProto.verify|verify} messages.
     * @function encode
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {IGetNetworkConfigurationResponseProto} message GetNetworkConfigurationResponseProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetNetworkConfigurationResponseProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.networkName != null && Object.hasOwnProperty.call(message, "networkName"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.networkName);
        if (message.networkDescription != null && Object.hasOwnProperty.call(message, "networkDescription"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.networkDescription);
        if (message.primaryColor != null && Object.hasOwnProperty.call(message, "primaryColor"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.primaryColor);
        if (message.backgroundColor != null && Object.hasOwnProperty.call(message, "backgroundColor"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.backgroundColor);
        if (message.cardColor != null && Object.hasOwnProperty.call(message, "cardColor"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.cardColor);
        return writer;
    };

    /**
     * Encodes the specified GetNetworkConfigurationResponseProto message, length delimited. Does not implicitly {@link GetNetworkConfigurationResponseProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {IGetNetworkConfigurationResponseProto} message GetNetworkConfigurationResponseProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetNetworkConfigurationResponseProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetNetworkConfigurationResponseProto message from the specified reader or buffer.
     * @function decode
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetNetworkConfigurationResponseProto} GetNetworkConfigurationResponseProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetNetworkConfigurationResponseProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetNetworkConfigurationResponseProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.networkName = reader.string();
                    break;
                }
            case 3: {
                    message.networkDescription = reader.string();
                    break;
                }
            case 4: {
                    message.primaryColor = reader.string();
                    break;
                }
            case 5: {
                    message.backgroundColor = reader.string();
                    break;
                }
            case 6: {
                    message.cardColor = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetNetworkConfigurationResponseProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetNetworkConfigurationResponseProto} GetNetworkConfigurationResponseProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetNetworkConfigurationResponseProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetNetworkConfigurationResponseProto message.
     * @function verify
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetNetworkConfigurationResponseProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.networkName != null && message.hasOwnProperty("networkName"))
            if (!$util.isString(message.networkName))
                return "networkName: string expected";
        if (message.networkDescription != null && message.hasOwnProperty("networkDescription"))
            if (!$util.isString(message.networkDescription))
                return "networkDescription: string expected";
        if (message.primaryColor != null && message.hasOwnProperty("primaryColor"))
            if (!$util.isString(message.primaryColor))
                return "primaryColor: string expected";
        if (message.backgroundColor != null && message.hasOwnProperty("backgroundColor"))
            if (!$util.isString(message.backgroundColor))
                return "backgroundColor: string expected";
        if (message.cardColor != null && message.hasOwnProperty("cardColor"))
            if (!$util.isString(message.cardColor))
                return "cardColor: string expected";
        return null;
    };

    /**
     * Creates a GetNetworkConfigurationResponseProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetNetworkConfigurationResponseProto} GetNetworkConfigurationResponseProto
     */
    GetNetworkConfigurationResponseProto.fromObject = function fromObject(object) {
        if (object instanceof $root.GetNetworkConfigurationResponseProto)
            return object;
        var message = new $root.GetNetworkConfigurationResponseProto();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.networkName != null)
            message.networkName = String(object.networkName);
        if (object.networkDescription != null)
            message.networkDescription = String(object.networkDescription);
        if (object.primaryColor != null)
            message.primaryColor = String(object.primaryColor);
        if (object.backgroundColor != null)
            message.backgroundColor = String(object.backgroundColor);
        if (object.cardColor != null)
            message.cardColor = String(object.cardColor);
        return message;
    };

    /**
     * Creates a plain object from a GetNetworkConfigurationResponseProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {GetNetworkConfigurationResponseProto} message GetNetworkConfigurationResponseProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetNetworkConfigurationResponseProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.networkName = "";
            object.networkDescription = "";
            object.primaryColor = "";
            object.backgroundColor = "";
            object.cardColor = "";
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.networkName != null && message.hasOwnProperty("networkName"))
            object.networkName = message.networkName;
        if (message.networkDescription != null && message.hasOwnProperty("networkDescription"))
            object.networkDescription = message.networkDescription;
        if (message.primaryColor != null && message.hasOwnProperty("primaryColor"))
            object.primaryColor = message.primaryColor;
        if (message.backgroundColor != null && message.hasOwnProperty("backgroundColor"))
            object.backgroundColor = message.backgroundColor;
        if (message.cardColor != null && message.hasOwnProperty("cardColor"))
            object.cardColor = message.cardColor;
        return object;
    };

    /**
     * Converts this GetNetworkConfigurationResponseProto to JSON.
     * @function toJSON
     * @memberof GetNetworkConfigurationResponseProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetNetworkConfigurationResponseProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetNetworkConfigurationResponseProto
     * @function getTypeUrl
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetNetworkConfigurationResponseProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetNetworkConfigurationResponseProto";
    };

    return GetNetworkConfigurationResponseProto;
})();

$root.ProviderProto = (function() {

    /**
     * Properties of a ProviderProto.
     * @exports IProviderProto
     * @interface IProviderProto
     * @property {string|null} [providerId] ProviderProto providerId
     * @property {string|null} [name] ProviderProto name
     * @property {string|null} [description] ProviderProto description
     * @property {string|null} [address] ProviderProto address
     * @property {number|null} [lat] ProviderProto lat
     * @property {number|null} [lng] ProviderProto lng
     * @property {string|null} [shortUrl] ProviderProto shortUrl
     * @property {Array.<IProviderServiceProto>|null} [services] ProviderProto services
     */

    /**
     * Constructs a new ProviderProto.
     * @exports ProviderProto
     * @classdesc Represents a ProviderProto.
     * @implements IProviderProto
     * @constructor
     * @param {IProviderProto=} [properties] Properties to set
     */
    function ProviderProto(properties) {
        this.services = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProviderProto providerId.
     * @member {string} providerId
     * @memberof ProviderProto
     * @instance
     */
    ProviderProto.prototype.providerId = "";

    /**
     * ProviderProto name.
     * @member {string} name
     * @memberof ProviderProto
     * @instance
     */
    ProviderProto.prototype.name = "";

    /**
     * ProviderProto description.
     * @member {string} description
     * @memberof ProviderProto
     * @instance
     */
    ProviderProto.prototype.description = "";

    /**
     * ProviderProto address.
     * @member {string} address
     * @memberof ProviderProto
     * @instance
     */
    ProviderProto.prototype.address = "";

    /**
     * ProviderProto lat.
     * @member {number} lat
     * @memberof ProviderProto
     * @instance
     */
    ProviderProto.prototype.lat = 0;

    /**
     * ProviderProto lng.
     * @member {number} lng
     * @memberof ProviderProto
     * @instance
     */
    ProviderProto.prototype.lng = 0;

    /**
     * ProviderProto shortUrl.
     * @member {string} shortUrl
     * @memberof ProviderProto
     * @instance
     */
    ProviderProto.prototype.shortUrl = "";

    /**
     * ProviderProto services.
     * @member {Array.<IProviderServiceProto>} services
     * @memberof ProviderProto
     * @instance
     */
    ProviderProto.prototype.services = $util.emptyArray;

    /**
     * Creates a new ProviderProto instance using the specified properties.
     * @function create
     * @memberof ProviderProto
     * @static
     * @param {IProviderProto=} [properties] Properties to set
     * @returns {ProviderProto} ProviderProto instance
     */
    ProviderProto.create = function create(properties) {
        return new ProviderProto(properties);
    };

    /**
     * Encodes the specified ProviderProto message. Does not implicitly {@link ProviderProto.verify|verify} messages.
     * @function encode
     * @memberof ProviderProto
     * @static
     * @param {IProviderProto} message ProviderProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProviderProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
        if (message.address != null && Object.hasOwnProperty.call(message, "address"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.address);
        if (message.lat != null && Object.hasOwnProperty.call(message, "lat"))
            writer.uint32(/* id 6, wireType 1 =*/49).double(message.lat);
        if (message.lng != null && Object.hasOwnProperty.call(message, "lng"))
            writer.uint32(/* id 7, wireType 1 =*/57).double(message.lng);
        if (message.shortUrl != null && Object.hasOwnProperty.call(message, "shortUrl"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.shortUrl);
        if (message.services != null && message.services.length)
            for (var i = 0; i < message.services.length; ++i)
                $root.ProviderServiceProto.encode(message.services[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ProviderProto message, length delimited. Does not implicitly {@link ProviderProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProviderProto
     * @static
     * @param {IProviderProto} message ProviderProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProviderProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProviderProto message from the specified reader or buffer.
     * @function decode
     * @memberof ProviderProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProviderProto} ProviderProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProviderProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProviderProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.providerId = reader.string();
                    break;
                }
            case 2: {
                    message.name = reader.string();
                    break;
                }
            case 3: {
                    message.description = reader.string();
                    break;
                }
            case 5: {
                    message.address = reader.string();
                    break;
                }
            case 6: {
                    message.lat = reader.double();
                    break;
                }
            case 7: {
                    message.lng = reader.double();
                    break;
                }
            case 8: {
                    message.shortUrl = reader.string();
                    break;
                }
            case 10: {
                    if (!(message.services && message.services.length))
                        message.services = [];
                    message.services.push($root.ProviderServiceProto.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProviderProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProviderProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProviderProto} ProviderProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProviderProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProviderProto message.
     * @function verify
     * @memberof ProviderProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProviderProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            if (!$util.isString(message.providerId))
                return "providerId: string expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.description != null && message.hasOwnProperty("description"))
            if (!$util.isString(message.description))
                return "description: string expected";
        if (message.address != null && message.hasOwnProperty("address"))
            if (!$util.isString(message.address))
                return "address: string expected";
        if (message.lat != null && message.hasOwnProperty("lat"))
            if (typeof message.lat !== "number")
                return "lat: number expected";
        if (message.lng != null && message.hasOwnProperty("lng"))
            if (typeof message.lng !== "number")
                return "lng: number expected";
        if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
            if (!$util.isString(message.shortUrl))
                return "shortUrl: string expected";
        if (message.services != null && message.hasOwnProperty("services")) {
            if (!Array.isArray(message.services))
                return "services: array expected";
            for (var i = 0; i < message.services.length; ++i) {
                var error = $root.ProviderServiceProto.verify(message.services[i]);
                if (error)
                    return "services." + error;
            }
        }
        return null;
    };

    /**
     * Creates a ProviderProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProviderProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProviderProto} ProviderProto
     */
    ProviderProto.fromObject = function fromObject(object) {
        if (object instanceof $root.ProviderProto)
            return object;
        var message = new $root.ProviderProto();
        if (object.providerId != null)
            message.providerId = String(object.providerId);
        if (object.name != null)
            message.name = String(object.name);
        if (object.description != null)
            message.description = String(object.description);
        if (object.address != null)
            message.address = String(object.address);
        if (object.lat != null)
            message.lat = Number(object.lat);
        if (object.lng != null)
            message.lng = Number(object.lng);
        if (object.shortUrl != null)
            message.shortUrl = String(object.shortUrl);
        if (object.services) {
            if (!Array.isArray(object.services))
                throw TypeError(".ProviderProto.services: array expected");
            message.services = [];
            for (var i = 0; i < object.services.length; ++i) {
                if (typeof object.services[i] !== "object")
                    throw TypeError(".ProviderProto.services: object expected");
                message.services[i] = $root.ProviderServiceProto.fromObject(object.services[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a ProviderProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProviderProto
     * @static
     * @param {ProviderProto} message ProviderProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProviderProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.services = [];
        if (options.defaults) {
            object.providerId = "";
            object.name = "";
            object.description = "";
            object.address = "";
            object.lat = 0;
            object.lng = 0;
            object.shortUrl = "";
        }
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            object.providerId = message.providerId;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.description != null && message.hasOwnProperty("description"))
            object.description = message.description;
        if (message.address != null && message.hasOwnProperty("address"))
            object.address = message.address;
        if (message.lat != null && message.hasOwnProperty("lat"))
            object.lat = options.json && !isFinite(message.lat) ? String(message.lat) : message.lat;
        if (message.lng != null && message.hasOwnProperty("lng"))
            object.lng = options.json && !isFinite(message.lng) ? String(message.lng) : message.lng;
        if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
            object.shortUrl = message.shortUrl;
        if (message.services && message.services.length) {
            object.services = [];
            for (var j = 0; j < message.services.length; ++j)
                object.services[j] = $root.ProviderServiceProto.toObject(message.services[j], options);
        }
        return object;
    };

    /**
     * Converts this ProviderProto to JSON.
     * @function toJSON
     * @memberof ProviderProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProviderProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProviderProto
     * @function getTypeUrl
     * @memberof ProviderProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProviderProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProviderProto";
    };

    return ProviderProto;
})();

$root.FindProviderRequest = (function() {

    /**
     * Properties of a FindProviderRequest.
     * @exports IFindProviderRequest
     * @interface IFindProviderRequest
     * @property {string|null} [category] FindProviderRequest category
     * @property {number|null} [lat] FindProviderRequest lat
     * @property {number|null} [lng] FindProviderRequest lng
     * @property {number|null} [page] FindProviderRequest page
     */

    /**
     * Constructs a new FindProviderRequest.
     * @exports FindProviderRequest
     * @classdesc Represents a FindProviderRequest.
     * @implements IFindProviderRequest
     * @constructor
     * @param {IFindProviderRequest=} [properties] Properties to set
     */
    function FindProviderRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * FindProviderRequest category.
     * @member {string} category
     * @memberof FindProviderRequest
     * @instance
     */
    FindProviderRequest.prototype.category = "";

    /**
     * FindProviderRequest lat.
     * @member {number} lat
     * @memberof FindProviderRequest
     * @instance
     */
    FindProviderRequest.prototype.lat = 0;

    /**
     * FindProviderRequest lng.
     * @member {number} lng
     * @memberof FindProviderRequest
     * @instance
     */
    FindProviderRequest.prototype.lng = 0;

    /**
     * FindProviderRequest page.
     * @member {number} page
     * @memberof FindProviderRequest
     * @instance
     */
    FindProviderRequest.prototype.page = 0;

    /**
     * Creates a new FindProviderRequest instance using the specified properties.
     * @function create
     * @memberof FindProviderRequest
     * @static
     * @param {IFindProviderRequest=} [properties] Properties to set
     * @returns {FindProviderRequest} FindProviderRequest instance
     */
    FindProviderRequest.create = function create(properties) {
        return new FindProviderRequest(properties);
    };

    /**
     * Encodes the specified FindProviderRequest message. Does not implicitly {@link FindProviderRequest.verify|verify} messages.
     * @function encode
     * @memberof FindProviderRequest
     * @static
     * @param {IFindProviderRequest} message FindProviderRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FindProviderRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.category != null && Object.hasOwnProperty.call(message, "category"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.category);
        if (message.lat != null && Object.hasOwnProperty.call(message, "lat"))
            writer.uint32(/* id 2, wireType 1 =*/17).double(message.lat);
        if (message.lng != null && Object.hasOwnProperty.call(message, "lng"))
            writer.uint32(/* id 3, wireType 1 =*/25).double(message.lng);
        if (message.page != null && Object.hasOwnProperty.call(message, "page"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.page);
        return writer;
    };

    /**
     * Encodes the specified FindProviderRequest message, length delimited. Does not implicitly {@link FindProviderRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof FindProviderRequest
     * @static
     * @param {IFindProviderRequest} message FindProviderRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FindProviderRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FindProviderRequest message from the specified reader or buffer.
     * @function decode
     * @memberof FindProviderRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {FindProviderRequest} FindProviderRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FindProviderRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.FindProviderRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.category = reader.string();
                    break;
                }
            case 2: {
                    message.lat = reader.double();
                    break;
                }
            case 3: {
                    message.lng = reader.double();
                    break;
                }
            case 4: {
                    message.page = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a FindProviderRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof FindProviderRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {FindProviderRequest} FindProviderRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FindProviderRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FindProviderRequest message.
     * @function verify
     * @memberof FindProviderRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FindProviderRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.category != null && message.hasOwnProperty("category"))
            if (!$util.isString(message.category))
                return "category: string expected";
        if (message.lat != null && message.hasOwnProperty("lat"))
            if (typeof message.lat !== "number")
                return "lat: number expected";
        if (message.lng != null && message.hasOwnProperty("lng"))
            if (typeof message.lng !== "number")
                return "lng: number expected";
        if (message.page != null && message.hasOwnProperty("page"))
            if (!$util.isInteger(message.page))
                return "page: integer expected";
        return null;
    };

    /**
     * Creates a FindProviderRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof FindProviderRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {FindProviderRequest} FindProviderRequest
     */
    FindProviderRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.FindProviderRequest)
            return object;
        var message = new $root.FindProviderRequest();
        if (object.category != null)
            message.category = String(object.category);
        if (object.lat != null)
            message.lat = Number(object.lat);
        if (object.lng != null)
            message.lng = Number(object.lng);
        if (object.page != null)
            message.page = object.page | 0;
        return message;
    };

    /**
     * Creates a plain object from a FindProviderRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof FindProviderRequest
     * @static
     * @param {FindProviderRequest} message FindProviderRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FindProviderRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.category = "";
            object.lat = 0;
            object.lng = 0;
            object.page = 0;
        }
        if (message.category != null && message.hasOwnProperty("category"))
            object.category = message.category;
        if (message.lat != null && message.hasOwnProperty("lat"))
            object.lat = options.json && !isFinite(message.lat) ? String(message.lat) : message.lat;
        if (message.lng != null && message.hasOwnProperty("lng"))
            object.lng = options.json && !isFinite(message.lng) ? String(message.lng) : message.lng;
        if (message.page != null && message.hasOwnProperty("page"))
            object.page = message.page;
        return object;
    };

    /**
     * Converts this FindProviderRequest to JSON.
     * @function toJSON
     * @memberof FindProviderRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FindProviderRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FindProviderRequest
     * @function getTypeUrl
     * @memberof FindProviderRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FindProviderRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/FindProviderRequest";
    };

    return FindProviderRequest;
})();

$root.FindProviderResponse = (function() {

    /**
     * Properties of a FindProviderResponse.
     * @exports IFindProviderResponse
     * @interface IFindProviderResponse
     * @property {boolean|null} [okay] FindProviderResponse okay
     * @property {Array.<IProviderProto>|null} [providers] FindProviderResponse providers
     * @property {boolean|null} [more] FindProviderResponse more
     */

    /**
     * Constructs a new FindProviderResponse.
     * @exports FindProviderResponse
     * @classdesc Represents a FindProviderResponse.
     * @implements IFindProviderResponse
     * @constructor
     * @param {IFindProviderResponse=} [properties] Properties to set
     */
    function FindProviderResponse(properties) {
        this.providers = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * FindProviderResponse okay.
     * @member {boolean} okay
     * @memberof FindProviderResponse
     * @instance
     */
    FindProviderResponse.prototype.okay = false;

    /**
     * FindProviderResponse providers.
     * @member {Array.<IProviderProto>} providers
     * @memberof FindProviderResponse
     * @instance
     */
    FindProviderResponse.prototype.providers = $util.emptyArray;

    /**
     * FindProviderResponse more.
     * @member {boolean} more
     * @memberof FindProviderResponse
     * @instance
     */
    FindProviderResponse.prototype.more = false;

    /**
     * Creates a new FindProviderResponse instance using the specified properties.
     * @function create
     * @memberof FindProviderResponse
     * @static
     * @param {IFindProviderResponse=} [properties] Properties to set
     * @returns {FindProviderResponse} FindProviderResponse instance
     */
    FindProviderResponse.create = function create(properties) {
        return new FindProviderResponse(properties);
    };

    /**
     * Encodes the specified FindProviderResponse message. Does not implicitly {@link FindProviderResponse.verify|verify} messages.
     * @function encode
     * @memberof FindProviderResponse
     * @static
     * @param {IFindProviderResponse} message FindProviderResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FindProviderResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.providers != null && message.providers.length)
            for (var i = 0; i < message.providers.length; ++i)
                $root.ProviderProto.encode(message.providers[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.more != null && Object.hasOwnProperty.call(message, "more"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.more);
        return writer;
    };

    /**
     * Encodes the specified FindProviderResponse message, length delimited. Does not implicitly {@link FindProviderResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof FindProviderResponse
     * @static
     * @param {IFindProviderResponse} message FindProviderResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FindProviderResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FindProviderResponse message from the specified reader or buffer.
     * @function decode
     * @memberof FindProviderResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {FindProviderResponse} FindProviderResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FindProviderResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.FindProviderResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    if (!(message.providers && message.providers.length))
                        message.providers = [];
                    message.providers.push($root.ProviderProto.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    message.more = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a FindProviderResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof FindProviderResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {FindProviderResponse} FindProviderResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FindProviderResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FindProviderResponse message.
     * @function verify
     * @memberof FindProviderResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FindProviderResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.providers != null && message.hasOwnProperty("providers")) {
            if (!Array.isArray(message.providers))
                return "providers: array expected";
            for (var i = 0; i < message.providers.length; ++i) {
                var error = $root.ProviderProto.verify(message.providers[i]);
                if (error)
                    return "providers." + error;
            }
        }
        if (message.more != null && message.hasOwnProperty("more"))
            if (typeof message.more !== "boolean")
                return "more: boolean expected";
        return null;
    };

    /**
     * Creates a FindProviderResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof FindProviderResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {FindProviderResponse} FindProviderResponse
     */
    FindProviderResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.FindProviderResponse)
            return object;
        var message = new $root.FindProviderResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.providers) {
            if (!Array.isArray(object.providers))
                throw TypeError(".FindProviderResponse.providers: array expected");
            message.providers = [];
            for (var i = 0; i < object.providers.length; ++i) {
                if (typeof object.providers[i] !== "object")
                    throw TypeError(".FindProviderResponse.providers: object expected");
                message.providers[i] = $root.ProviderProto.fromObject(object.providers[i]);
            }
        }
        if (object.more != null)
            message.more = Boolean(object.more);
        return message;
    };

    /**
     * Creates a plain object from a FindProviderResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof FindProviderResponse
     * @static
     * @param {FindProviderResponse} message FindProviderResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FindProviderResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.providers = [];
        if (options.defaults) {
            object.okay = false;
            object.more = false;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.providers && message.providers.length) {
            object.providers = [];
            for (var j = 0; j < message.providers.length; ++j)
                object.providers[j] = $root.ProviderProto.toObject(message.providers[j], options);
        }
        if (message.more != null && message.hasOwnProperty("more"))
            object.more = message.more;
        return object;
    };

    /**
     * Converts this FindProviderResponse to JSON.
     * @function toJSON
     * @memberof FindProviderResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FindProviderResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FindProviderResponse
     * @function getTypeUrl
     * @memberof FindProviderResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FindProviderResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/FindProviderResponse";
    };

    return FindProviderResponse;
})();

$root.GetProviderProfileRequest = (function() {

    /**
     * Properties of a GetProviderProfileRequest.
     * @exports IGetProviderProfileRequest
     * @interface IGetProviderProfileRequest
     * @property {string|null} [shortUrl] GetProviderProfileRequest shortUrl
     */

    /**
     * Constructs a new GetProviderProfileRequest.
     * @exports GetProviderProfileRequest
     * @classdesc Represents a GetProviderProfileRequest.
     * @implements IGetProviderProfileRequest
     * @constructor
     * @param {IGetProviderProfileRequest=} [properties] Properties to set
     */
    function GetProviderProfileRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetProviderProfileRequest shortUrl.
     * @member {string} shortUrl
     * @memberof GetProviderProfileRequest
     * @instance
     */
    GetProviderProfileRequest.prototype.shortUrl = "";

    /**
     * Creates a new GetProviderProfileRequest instance using the specified properties.
     * @function create
     * @memberof GetProviderProfileRequest
     * @static
     * @param {IGetProviderProfileRequest=} [properties] Properties to set
     * @returns {GetProviderProfileRequest} GetProviderProfileRequest instance
     */
    GetProviderProfileRequest.create = function create(properties) {
        return new GetProviderProfileRequest(properties);
    };

    /**
     * Encodes the specified GetProviderProfileRequest message. Does not implicitly {@link GetProviderProfileRequest.verify|verify} messages.
     * @function encode
     * @memberof GetProviderProfileRequest
     * @static
     * @param {IGetProviderProfileRequest} message GetProviderProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetProviderProfileRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.shortUrl != null && Object.hasOwnProperty.call(message, "shortUrl"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.shortUrl);
        return writer;
    };

    /**
     * Encodes the specified GetProviderProfileRequest message, length delimited. Does not implicitly {@link GetProviderProfileRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetProviderProfileRequest
     * @static
     * @param {IGetProviderProfileRequest} message GetProviderProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetProviderProfileRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetProviderProfileRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetProviderProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetProviderProfileRequest} GetProviderProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetProviderProfileRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetProviderProfileRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.shortUrl = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetProviderProfileRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetProviderProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetProviderProfileRequest} GetProviderProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetProviderProfileRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetProviderProfileRequest message.
     * @function verify
     * @memberof GetProviderProfileRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetProviderProfileRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
            if (!$util.isString(message.shortUrl))
                return "shortUrl: string expected";
        return null;
    };

    /**
     * Creates a GetProviderProfileRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetProviderProfileRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetProviderProfileRequest} GetProviderProfileRequest
     */
    GetProviderProfileRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetProviderProfileRequest)
            return object;
        var message = new $root.GetProviderProfileRequest();
        if (object.shortUrl != null)
            message.shortUrl = String(object.shortUrl);
        return message;
    };

    /**
     * Creates a plain object from a GetProviderProfileRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetProviderProfileRequest
     * @static
     * @param {GetProviderProfileRequest} message GetProviderProfileRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetProviderProfileRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.shortUrl = "";
        if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
            object.shortUrl = message.shortUrl;
        return object;
    };

    /**
     * Converts this GetProviderProfileRequest to JSON.
     * @function toJSON
     * @memberof GetProviderProfileRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetProviderProfileRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetProviderProfileRequest
     * @function getTypeUrl
     * @memberof GetProviderProfileRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetProviderProfileRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetProviderProfileRequest";
    };

    return GetProviderProfileRequest;
})();

$root.GetProviderProfileResponse = (function() {

    /**
     * Properties of a GetProviderProfileResponse.
     * @exports IGetProviderProfileResponse
     * @interface IGetProviderProfileResponse
     * @property {boolean|null} [okay] GetProviderProfileResponse okay
     * @property {IProviderProto|null} [provider] GetProviderProfileResponse provider
     */

    /**
     * Constructs a new GetProviderProfileResponse.
     * @exports GetProviderProfileResponse
     * @classdesc Represents a GetProviderProfileResponse.
     * @implements IGetProviderProfileResponse
     * @constructor
     * @param {IGetProviderProfileResponse=} [properties] Properties to set
     */
    function GetProviderProfileResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetProviderProfileResponse okay.
     * @member {boolean} okay
     * @memberof GetProviderProfileResponse
     * @instance
     */
    GetProviderProfileResponse.prototype.okay = false;

    /**
     * GetProviderProfileResponse provider.
     * @member {IProviderProto|null|undefined} provider
     * @memberof GetProviderProfileResponse
     * @instance
     */
    GetProviderProfileResponse.prototype.provider = null;

    /**
     * Creates a new GetProviderProfileResponse instance using the specified properties.
     * @function create
     * @memberof GetProviderProfileResponse
     * @static
     * @param {IGetProviderProfileResponse=} [properties] Properties to set
     * @returns {GetProviderProfileResponse} GetProviderProfileResponse instance
     */
    GetProviderProfileResponse.create = function create(properties) {
        return new GetProviderProfileResponse(properties);
    };

    /**
     * Encodes the specified GetProviderProfileResponse message. Does not implicitly {@link GetProviderProfileResponse.verify|verify} messages.
     * @function encode
     * @memberof GetProviderProfileResponse
     * @static
     * @param {IGetProviderProfileResponse} message GetProviderProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetProviderProfileResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.provider != null && Object.hasOwnProperty.call(message, "provider"))
            $root.ProviderProto.encode(message.provider, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetProviderProfileResponse message, length delimited. Does not implicitly {@link GetProviderProfileResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetProviderProfileResponse
     * @static
     * @param {IGetProviderProfileResponse} message GetProviderProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetProviderProfileResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetProviderProfileResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetProviderProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetProviderProfileResponse} GetProviderProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetProviderProfileResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetProviderProfileResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.provider = $root.ProviderProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetProviderProfileResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetProviderProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetProviderProfileResponse} GetProviderProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetProviderProfileResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetProviderProfileResponse message.
     * @function verify
     * @memberof GetProviderProfileResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetProviderProfileResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.provider != null && message.hasOwnProperty("provider")) {
            var error = $root.ProviderProto.verify(message.provider);
            if (error)
                return "provider." + error;
        }
        return null;
    };

    /**
     * Creates a GetProviderProfileResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetProviderProfileResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetProviderProfileResponse} GetProviderProfileResponse
     */
    GetProviderProfileResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetProviderProfileResponse)
            return object;
        var message = new $root.GetProviderProfileResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.provider != null) {
            if (typeof object.provider !== "object")
                throw TypeError(".GetProviderProfileResponse.provider: object expected");
            message.provider = $root.ProviderProto.fromObject(object.provider);
        }
        return message;
    };

    /**
     * Creates a plain object from a GetProviderProfileResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetProviderProfileResponse
     * @static
     * @param {GetProviderProfileResponse} message GetProviderProfileResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetProviderProfileResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.provider = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.provider != null && message.hasOwnProperty("provider"))
            object.provider = $root.ProviderProto.toObject(message.provider, options);
        return object;
    };

    /**
     * Converts this GetProviderProfileResponse to JSON.
     * @function toJSON
     * @memberof GetProviderProfileResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetProviderProfileResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetProviderProfileResponse
     * @function getTypeUrl
     * @memberof GetProviderProfileResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetProviderProfileResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetProviderProfileResponse";
    };

    return GetProviderProfileResponse;
})();

$root.ProviderServiceProto = (function() {

    /**
     * Properties of a ProviderServiceProto.
     * @exports IProviderServiceProto
     * @interface IProviderServiceProto
     * @property {string|null} [sku] ProviderServiceProto sku
     * @property {string|null} [name] ProviderServiceProto name
     * @property {string|null} [description] ProviderServiceProto description
     * @property {number|null} [lengthMinutes] ProviderServiceProto lengthMinutes
     * @property {number|null} [price] ProviderServiceProto price
     * @property {string|null} [currencyCode] ProviderServiceProto currencyCode
     */

    /**
     * Constructs a new ProviderServiceProto.
     * @exports ProviderServiceProto
     * @classdesc Represents a ProviderServiceProto.
     * @implements IProviderServiceProto
     * @constructor
     * @param {IProviderServiceProto=} [properties] Properties to set
     */
    function ProviderServiceProto(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProviderServiceProto sku.
     * @member {string} sku
     * @memberof ProviderServiceProto
     * @instance
     */
    ProviderServiceProto.prototype.sku = "";

    /**
     * ProviderServiceProto name.
     * @member {string} name
     * @memberof ProviderServiceProto
     * @instance
     */
    ProviderServiceProto.prototype.name = "";

    /**
     * ProviderServiceProto description.
     * @member {string} description
     * @memberof ProviderServiceProto
     * @instance
     */
    ProviderServiceProto.prototype.description = "";

    /**
     * ProviderServiceProto lengthMinutes.
     * @member {number} lengthMinutes
     * @memberof ProviderServiceProto
     * @instance
     */
    ProviderServiceProto.prototype.lengthMinutes = 0;

    /**
     * ProviderServiceProto price.
     * @member {number} price
     * @memberof ProviderServiceProto
     * @instance
     */
    ProviderServiceProto.prototype.price = 0;

    /**
     * ProviderServiceProto currencyCode.
     * @member {string} currencyCode
     * @memberof ProviderServiceProto
     * @instance
     */
    ProviderServiceProto.prototype.currencyCode = "";

    /**
     * Creates a new ProviderServiceProto instance using the specified properties.
     * @function create
     * @memberof ProviderServiceProto
     * @static
     * @param {IProviderServiceProto=} [properties] Properties to set
     * @returns {ProviderServiceProto} ProviderServiceProto instance
     */
    ProviderServiceProto.create = function create(properties) {
        return new ProviderServiceProto(properties);
    };

    /**
     * Encodes the specified ProviderServiceProto message. Does not implicitly {@link ProviderServiceProto.verify|verify} messages.
     * @function encode
     * @memberof ProviderServiceProto
     * @static
     * @param {IProviderServiceProto} message ProviderServiceProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProviderServiceProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.sku != null && Object.hasOwnProperty.call(message, "sku"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.sku);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
        if (message.lengthMinutes != null && Object.hasOwnProperty.call(message, "lengthMinutes"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.lengthMinutes);
        if (message.price != null && Object.hasOwnProperty.call(message, "price"))
            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.price);
        if (message.currencyCode != null && Object.hasOwnProperty.call(message, "currencyCode"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.currencyCode);
        return writer;
    };

    /**
     * Encodes the specified ProviderServiceProto message, length delimited. Does not implicitly {@link ProviderServiceProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProviderServiceProto
     * @static
     * @param {IProviderServiceProto} message ProviderServiceProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProviderServiceProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProviderServiceProto message from the specified reader or buffer.
     * @function decode
     * @memberof ProviderServiceProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProviderServiceProto} ProviderServiceProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProviderServiceProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProviderServiceProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.sku = reader.string();
                    break;
                }
            case 2: {
                    message.name = reader.string();
                    break;
                }
            case 3: {
                    message.description = reader.string();
                    break;
                }
            case 4: {
                    message.lengthMinutes = reader.int32();
                    break;
                }
            case 5: {
                    message.price = reader.int32();
                    break;
                }
            case 6: {
                    message.currencyCode = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProviderServiceProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProviderServiceProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProviderServiceProto} ProviderServiceProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProviderServiceProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProviderServiceProto message.
     * @function verify
     * @memberof ProviderServiceProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProviderServiceProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.sku != null && message.hasOwnProperty("sku"))
            if (!$util.isString(message.sku))
                return "sku: string expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.description != null && message.hasOwnProperty("description"))
            if (!$util.isString(message.description))
                return "description: string expected";
        if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
            if (!$util.isInteger(message.lengthMinutes))
                return "lengthMinutes: integer expected";
        if (message.price != null && message.hasOwnProperty("price"))
            if (!$util.isInteger(message.price))
                return "price: integer expected";
        if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
            if (!$util.isString(message.currencyCode))
                return "currencyCode: string expected";
        return null;
    };

    /**
     * Creates a ProviderServiceProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProviderServiceProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProviderServiceProto} ProviderServiceProto
     */
    ProviderServiceProto.fromObject = function fromObject(object) {
        if (object instanceof $root.ProviderServiceProto)
            return object;
        var message = new $root.ProviderServiceProto();
        if (object.sku != null)
            message.sku = String(object.sku);
        if (object.name != null)
            message.name = String(object.name);
        if (object.description != null)
            message.description = String(object.description);
        if (object.lengthMinutes != null)
            message.lengthMinutes = object.lengthMinutes | 0;
        if (object.price != null)
            message.price = object.price | 0;
        if (object.currencyCode != null)
            message.currencyCode = String(object.currencyCode);
        return message;
    };

    /**
     * Creates a plain object from a ProviderServiceProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProviderServiceProto
     * @static
     * @param {ProviderServiceProto} message ProviderServiceProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProviderServiceProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.sku = "";
            object.name = "";
            object.description = "";
            object.lengthMinutes = 0;
            object.price = 0;
            object.currencyCode = "";
        }
        if (message.sku != null && message.hasOwnProperty("sku"))
            object.sku = message.sku;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.description != null && message.hasOwnProperty("description"))
            object.description = message.description;
        if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
            object.lengthMinutes = message.lengthMinutes;
        if (message.price != null && message.hasOwnProperty("price"))
            object.price = message.price;
        if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
            object.currencyCode = message.currencyCode;
        return object;
    };

    /**
     * Converts this ProviderServiceProto to JSON.
     * @function toJSON
     * @memberof ProviderServiceProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProviderServiceProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProviderServiceProto
     * @function getTypeUrl
     * @memberof ProviderServiceProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProviderServiceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProviderServiceProto";
    };

    return ProviderServiceProto;
})();

$root.GetAvailableAppointmentsRequest = (function() {

    /**
     * Properties of a GetAvailableAppointmentsRequest.
     * @exports IGetAvailableAppointmentsRequest
     * @interface IGetAvailableAppointmentsRequest
     * @property {string|null} [sku] GetAvailableAppointmentsRequest sku
     * @property {string|null} [providerId] GetAvailableAppointmentsRequest providerId
     * @property {space.narwhal.datetime.ILocalDateProto|null} [from] GetAvailableAppointmentsRequest from
     * @property {space.narwhal.datetime.ILocalDateProto|null} [to] GetAvailableAppointmentsRequest to
     * @property {string|null} [timeZoneId] GetAvailableAppointmentsRequest timeZoneId
     */

    /**
     * Constructs a new GetAvailableAppointmentsRequest.
     * @exports GetAvailableAppointmentsRequest
     * @classdesc Represents a GetAvailableAppointmentsRequest.
     * @implements IGetAvailableAppointmentsRequest
     * @constructor
     * @param {IGetAvailableAppointmentsRequest=} [properties] Properties to set
     */
    function GetAvailableAppointmentsRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetAvailableAppointmentsRequest sku.
     * @member {string} sku
     * @memberof GetAvailableAppointmentsRequest
     * @instance
     */
    GetAvailableAppointmentsRequest.prototype.sku = "";

    /**
     * GetAvailableAppointmentsRequest providerId.
     * @member {string} providerId
     * @memberof GetAvailableAppointmentsRequest
     * @instance
     */
    GetAvailableAppointmentsRequest.prototype.providerId = "";

    /**
     * GetAvailableAppointmentsRequest from.
     * @member {space.narwhal.datetime.ILocalDateProto|null|undefined} from
     * @memberof GetAvailableAppointmentsRequest
     * @instance
     */
    GetAvailableAppointmentsRequest.prototype.from = null;

    /**
     * GetAvailableAppointmentsRequest to.
     * @member {space.narwhal.datetime.ILocalDateProto|null|undefined} to
     * @memberof GetAvailableAppointmentsRequest
     * @instance
     */
    GetAvailableAppointmentsRequest.prototype.to = null;

    /**
     * GetAvailableAppointmentsRequest timeZoneId.
     * @member {string} timeZoneId
     * @memberof GetAvailableAppointmentsRequest
     * @instance
     */
    GetAvailableAppointmentsRequest.prototype.timeZoneId = "";

    /**
     * Creates a new GetAvailableAppointmentsRequest instance using the specified properties.
     * @function create
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {IGetAvailableAppointmentsRequest=} [properties] Properties to set
     * @returns {GetAvailableAppointmentsRequest} GetAvailableAppointmentsRequest instance
     */
    GetAvailableAppointmentsRequest.create = function create(properties) {
        return new GetAvailableAppointmentsRequest(properties);
    };

    /**
     * Encodes the specified GetAvailableAppointmentsRequest message. Does not implicitly {@link GetAvailableAppointmentsRequest.verify|verify} messages.
     * @function encode
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {IGetAvailableAppointmentsRequest} message GetAvailableAppointmentsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetAvailableAppointmentsRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.sku != null && Object.hasOwnProperty.call(message, "sku"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.sku);
        if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.providerId);
        if (message.from != null && Object.hasOwnProperty.call(message, "from"))
            $root.space.narwhal.datetime.LocalDateProto.encode(message.from, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.to != null && Object.hasOwnProperty.call(message, "to"))
            $root.space.narwhal.datetime.LocalDateProto.encode(message.to, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.timeZoneId != null && Object.hasOwnProperty.call(message, "timeZoneId"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.timeZoneId);
        return writer;
    };

    /**
     * Encodes the specified GetAvailableAppointmentsRequest message, length delimited. Does not implicitly {@link GetAvailableAppointmentsRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {IGetAvailableAppointmentsRequest} message GetAvailableAppointmentsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetAvailableAppointmentsRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetAvailableAppointmentsRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetAvailableAppointmentsRequest} GetAvailableAppointmentsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetAvailableAppointmentsRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetAvailableAppointmentsRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.sku = reader.string();
                    break;
                }
            case 2: {
                    message.providerId = reader.string();
                    break;
                }
            case 3: {
                    message.from = $root.space.narwhal.datetime.LocalDateProto.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.to = $root.space.narwhal.datetime.LocalDateProto.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.timeZoneId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetAvailableAppointmentsRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetAvailableAppointmentsRequest} GetAvailableAppointmentsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetAvailableAppointmentsRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetAvailableAppointmentsRequest message.
     * @function verify
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetAvailableAppointmentsRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.sku != null && message.hasOwnProperty("sku"))
            if (!$util.isString(message.sku))
                return "sku: string expected";
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            if (!$util.isString(message.providerId))
                return "providerId: string expected";
        if (message.from != null && message.hasOwnProperty("from")) {
            var error = $root.space.narwhal.datetime.LocalDateProto.verify(message.from);
            if (error)
                return "from." + error;
        }
        if (message.to != null && message.hasOwnProperty("to")) {
            var error = $root.space.narwhal.datetime.LocalDateProto.verify(message.to);
            if (error)
                return "to." + error;
        }
        if (message.timeZoneId != null && message.hasOwnProperty("timeZoneId"))
            if (!$util.isString(message.timeZoneId))
                return "timeZoneId: string expected";
        return null;
    };

    /**
     * Creates a GetAvailableAppointmentsRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetAvailableAppointmentsRequest} GetAvailableAppointmentsRequest
     */
    GetAvailableAppointmentsRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetAvailableAppointmentsRequest)
            return object;
        var message = new $root.GetAvailableAppointmentsRequest();
        if (object.sku != null)
            message.sku = String(object.sku);
        if (object.providerId != null)
            message.providerId = String(object.providerId);
        if (object.from != null) {
            if (typeof object.from !== "object")
                throw TypeError(".GetAvailableAppointmentsRequest.from: object expected");
            message.from = $root.space.narwhal.datetime.LocalDateProto.fromObject(object.from);
        }
        if (object.to != null) {
            if (typeof object.to !== "object")
                throw TypeError(".GetAvailableAppointmentsRequest.to: object expected");
            message.to = $root.space.narwhal.datetime.LocalDateProto.fromObject(object.to);
        }
        if (object.timeZoneId != null)
            message.timeZoneId = String(object.timeZoneId);
        return message;
    };

    /**
     * Creates a plain object from a GetAvailableAppointmentsRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {GetAvailableAppointmentsRequest} message GetAvailableAppointmentsRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetAvailableAppointmentsRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.sku = "";
            object.providerId = "";
            object.from = null;
            object.to = null;
            object.timeZoneId = "";
        }
        if (message.sku != null && message.hasOwnProperty("sku"))
            object.sku = message.sku;
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            object.providerId = message.providerId;
        if (message.from != null && message.hasOwnProperty("from"))
            object.from = $root.space.narwhal.datetime.LocalDateProto.toObject(message.from, options);
        if (message.to != null && message.hasOwnProperty("to"))
            object.to = $root.space.narwhal.datetime.LocalDateProto.toObject(message.to, options);
        if (message.timeZoneId != null && message.hasOwnProperty("timeZoneId"))
            object.timeZoneId = message.timeZoneId;
        return object;
    };

    /**
     * Converts this GetAvailableAppointmentsRequest to JSON.
     * @function toJSON
     * @memberof GetAvailableAppointmentsRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetAvailableAppointmentsRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetAvailableAppointmentsRequest
     * @function getTypeUrl
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetAvailableAppointmentsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetAvailableAppointmentsRequest";
    };

    return GetAvailableAppointmentsRequest;
})();

$root.GetAvailableAppointmentsResponse = (function() {

    /**
     * Properties of a GetAvailableAppointmentsResponse.
     * @exports IGetAvailableAppointmentsResponse
     * @interface IGetAvailableAppointmentsResponse
     * @property {boolean|null} [okay] GetAvailableAppointmentsResponse okay
     * @property {Array.<space.narwhal.datetime.IDateTimeProto>|null} [availableTimes] GetAvailableAppointmentsResponse availableTimes
     */

    /**
     * Constructs a new GetAvailableAppointmentsResponse.
     * @exports GetAvailableAppointmentsResponse
     * @classdesc Represents a GetAvailableAppointmentsResponse.
     * @implements IGetAvailableAppointmentsResponse
     * @constructor
     * @param {IGetAvailableAppointmentsResponse=} [properties] Properties to set
     */
    function GetAvailableAppointmentsResponse(properties) {
        this.availableTimes = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetAvailableAppointmentsResponse okay.
     * @member {boolean} okay
     * @memberof GetAvailableAppointmentsResponse
     * @instance
     */
    GetAvailableAppointmentsResponse.prototype.okay = false;

    /**
     * GetAvailableAppointmentsResponse availableTimes.
     * @member {Array.<space.narwhal.datetime.IDateTimeProto>} availableTimes
     * @memberof GetAvailableAppointmentsResponse
     * @instance
     */
    GetAvailableAppointmentsResponse.prototype.availableTimes = $util.emptyArray;

    /**
     * Creates a new GetAvailableAppointmentsResponse instance using the specified properties.
     * @function create
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {IGetAvailableAppointmentsResponse=} [properties] Properties to set
     * @returns {GetAvailableAppointmentsResponse} GetAvailableAppointmentsResponse instance
     */
    GetAvailableAppointmentsResponse.create = function create(properties) {
        return new GetAvailableAppointmentsResponse(properties);
    };

    /**
     * Encodes the specified GetAvailableAppointmentsResponse message. Does not implicitly {@link GetAvailableAppointmentsResponse.verify|verify} messages.
     * @function encode
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {IGetAvailableAppointmentsResponse} message GetAvailableAppointmentsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetAvailableAppointmentsResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.availableTimes != null && message.availableTimes.length)
            for (var i = 0; i < message.availableTimes.length; ++i)
                $root.space.narwhal.datetime.DateTimeProto.encode(message.availableTimes[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetAvailableAppointmentsResponse message, length delimited. Does not implicitly {@link GetAvailableAppointmentsResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {IGetAvailableAppointmentsResponse} message GetAvailableAppointmentsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetAvailableAppointmentsResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetAvailableAppointmentsResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetAvailableAppointmentsResponse} GetAvailableAppointmentsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetAvailableAppointmentsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetAvailableAppointmentsResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    if (!(message.availableTimes && message.availableTimes.length))
                        message.availableTimes = [];
                    message.availableTimes.push($root.space.narwhal.datetime.DateTimeProto.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetAvailableAppointmentsResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetAvailableAppointmentsResponse} GetAvailableAppointmentsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetAvailableAppointmentsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetAvailableAppointmentsResponse message.
     * @function verify
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetAvailableAppointmentsResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.availableTimes != null && message.hasOwnProperty("availableTimes")) {
            if (!Array.isArray(message.availableTimes))
                return "availableTimes: array expected";
            for (var i = 0; i < message.availableTimes.length; ++i) {
                var error = $root.space.narwhal.datetime.DateTimeProto.verify(message.availableTimes[i]);
                if (error)
                    return "availableTimes." + error;
            }
        }
        return null;
    };

    /**
     * Creates a GetAvailableAppointmentsResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetAvailableAppointmentsResponse} GetAvailableAppointmentsResponse
     */
    GetAvailableAppointmentsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetAvailableAppointmentsResponse)
            return object;
        var message = new $root.GetAvailableAppointmentsResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.availableTimes) {
            if (!Array.isArray(object.availableTimes))
                throw TypeError(".GetAvailableAppointmentsResponse.availableTimes: array expected");
            message.availableTimes = [];
            for (var i = 0; i < object.availableTimes.length; ++i) {
                if (typeof object.availableTimes[i] !== "object")
                    throw TypeError(".GetAvailableAppointmentsResponse.availableTimes: object expected");
                message.availableTimes[i] = $root.space.narwhal.datetime.DateTimeProto.fromObject(object.availableTimes[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a GetAvailableAppointmentsResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {GetAvailableAppointmentsResponse} message GetAvailableAppointmentsResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetAvailableAppointmentsResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.availableTimes = [];
        if (options.defaults)
            object.okay = false;
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.availableTimes && message.availableTimes.length) {
            object.availableTimes = [];
            for (var j = 0; j < message.availableTimes.length; ++j)
                object.availableTimes[j] = $root.space.narwhal.datetime.DateTimeProto.toObject(message.availableTimes[j], options);
        }
        return object;
    };

    /**
     * Converts this GetAvailableAppointmentsResponse to JSON.
     * @function toJSON
     * @memberof GetAvailableAppointmentsResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetAvailableAppointmentsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetAvailableAppointmentsResponse
     * @function getTypeUrl
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetAvailableAppointmentsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetAvailableAppointmentsResponse";
    };

    return GetAvailableAppointmentsResponse;
})();

$root.GetUserProfileRequest = (function() {

    /**
     * Properties of a GetUserProfileRequest.
     * @exports IGetUserProfileRequest
     * @interface IGetUserProfileRequest
     */

    /**
     * Constructs a new GetUserProfileRequest.
     * @exports GetUserProfileRequest
     * @classdesc Represents a GetUserProfileRequest.
     * @implements IGetUserProfileRequest
     * @constructor
     * @param {IGetUserProfileRequest=} [properties] Properties to set
     */
    function GetUserProfileRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new GetUserProfileRequest instance using the specified properties.
     * @function create
     * @memberof GetUserProfileRequest
     * @static
     * @param {IGetUserProfileRequest=} [properties] Properties to set
     * @returns {GetUserProfileRequest} GetUserProfileRequest instance
     */
    GetUserProfileRequest.create = function create(properties) {
        return new GetUserProfileRequest(properties);
    };

    /**
     * Encodes the specified GetUserProfileRequest message. Does not implicitly {@link GetUserProfileRequest.verify|verify} messages.
     * @function encode
     * @memberof GetUserProfileRequest
     * @static
     * @param {IGetUserProfileRequest} message GetUserProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserProfileRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified GetUserProfileRequest message, length delimited. Does not implicitly {@link GetUserProfileRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetUserProfileRequest
     * @static
     * @param {IGetUserProfileRequest} message GetUserProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserProfileRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetUserProfileRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetUserProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetUserProfileRequest} GetUserProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserProfileRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetUserProfileRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetUserProfileRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetUserProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetUserProfileRequest} GetUserProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserProfileRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetUserProfileRequest message.
     * @function verify
     * @memberof GetUserProfileRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetUserProfileRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a GetUserProfileRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetUserProfileRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetUserProfileRequest} GetUserProfileRequest
     */
    GetUserProfileRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetUserProfileRequest)
            return object;
        return new $root.GetUserProfileRequest();
    };

    /**
     * Creates a plain object from a GetUserProfileRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetUserProfileRequest
     * @static
     * @param {GetUserProfileRequest} message GetUserProfileRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetUserProfileRequest.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this GetUserProfileRequest to JSON.
     * @function toJSON
     * @memberof GetUserProfileRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetUserProfileRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetUserProfileRequest
     * @function getTypeUrl
     * @memberof GetUserProfileRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetUserProfileRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetUserProfileRequest";
    };

    return GetUserProfileRequest;
})();

$root.GetUserProfileResponse = (function() {

    /**
     * Properties of a GetUserProfileResponse.
     * @exports IGetUserProfileResponse
     * @interface IGetUserProfileResponse
     * @property {boolean|null} [okay] GetUserProfileResponse okay
     * @property {boolean|null} [isWhitelisted] GetUserProfileResponse isWhitelisted
     */

    /**
     * Constructs a new GetUserProfileResponse.
     * @exports GetUserProfileResponse
     * @classdesc Represents a GetUserProfileResponse.
     * @implements IGetUserProfileResponse
     * @constructor
     * @param {IGetUserProfileResponse=} [properties] Properties to set
     */
    function GetUserProfileResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetUserProfileResponse okay.
     * @member {boolean} okay
     * @memberof GetUserProfileResponse
     * @instance
     */
    GetUserProfileResponse.prototype.okay = false;

    /**
     * GetUserProfileResponse isWhitelisted.
     * @member {boolean} isWhitelisted
     * @memberof GetUserProfileResponse
     * @instance
     */
    GetUserProfileResponse.prototype.isWhitelisted = false;

    /**
     * Creates a new GetUserProfileResponse instance using the specified properties.
     * @function create
     * @memberof GetUserProfileResponse
     * @static
     * @param {IGetUserProfileResponse=} [properties] Properties to set
     * @returns {GetUserProfileResponse} GetUserProfileResponse instance
     */
    GetUserProfileResponse.create = function create(properties) {
        return new GetUserProfileResponse(properties);
    };

    /**
     * Encodes the specified GetUserProfileResponse message. Does not implicitly {@link GetUserProfileResponse.verify|verify} messages.
     * @function encode
     * @memberof GetUserProfileResponse
     * @static
     * @param {IGetUserProfileResponse} message GetUserProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserProfileResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.isWhitelisted != null && Object.hasOwnProperty.call(message, "isWhitelisted"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isWhitelisted);
        return writer;
    };

    /**
     * Encodes the specified GetUserProfileResponse message, length delimited. Does not implicitly {@link GetUserProfileResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetUserProfileResponse
     * @static
     * @param {IGetUserProfileResponse} message GetUserProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserProfileResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetUserProfileResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetUserProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetUserProfileResponse} GetUserProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserProfileResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetUserProfileResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.isWhitelisted = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetUserProfileResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetUserProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetUserProfileResponse} GetUserProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserProfileResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetUserProfileResponse message.
     * @function verify
     * @memberof GetUserProfileResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetUserProfileResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.isWhitelisted != null && message.hasOwnProperty("isWhitelisted"))
            if (typeof message.isWhitelisted !== "boolean")
                return "isWhitelisted: boolean expected";
        return null;
    };

    /**
     * Creates a GetUserProfileResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetUserProfileResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetUserProfileResponse} GetUserProfileResponse
     */
    GetUserProfileResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetUserProfileResponse)
            return object;
        var message = new $root.GetUserProfileResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.isWhitelisted != null)
            message.isWhitelisted = Boolean(object.isWhitelisted);
        return message;
    };

    /**
     * Creates a plain object from a GetUserProfileResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetUserProfileResponse
     * @static
     * @param {GetUserProfileResponse} message GetUserProfileResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetUserProfileResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.isWhitelisted = false;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.isWhitelisted != null && message.hasOwnProperty("isWhitelisted"))
            object.isWhitelisted = message.isWhitelisted;
        return object;
    };

    /**
     * Converts this GetUserProfileResponse to JSON.
     * @function toJSON
     * @memberof GetUserProfileResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetUserProfileResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetUserProfileResponse
     * @function getTypeUrl
     * @memberof GetUserProfileResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetUserProfileResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetUserProfileResponse";
    };

    return GetUserProfileResponse;
})();

$root.space = (function() {

    /**
     * Namespace space.
     * @exports space
     * @namespace
     */
    var space = {};

    space.narwhal = (function() {

        /**
         * Namespace narwhal.
         * @memberof space
         * @namespace
         */
        var narwhal = {};

        narwhal.datetime = (function() {

            /**
             * Namespace datetime.
             * @memberof space.narwhal
             * @namespace
             */
            var datetime = {};

            datetime.LocalDateProto = (function() {

                /**
                 * Properties of a LocalDateProto.
                 * @memberof space.narwhal.datetime
                 * @interface ILocalDateProto
                 * @property {number|null} [year] LocalDateProto year
                 * @property {number|null} [month] LocalDateProto month
                 * @property {number|null} [date] LocalDateProto date
                 */

                /**
                 * Constructs a new LocalDateProto.
                 * @memberof space.narwhal.datetime
                 * @classdesc Represents a LocalDateProto.
                 * @implements ILocalDateProto
                 * @constructor
                 * @param {space.narwhal.datetime.ILocalDateProto=} [properties] Properties to set
                 */
                function LocalDateProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LocalDateProto year.
                 * @member {number} year
                 * @memberof space.narwhal.datetime.LocalDateProto
                 * @instance
                 */
                LocalDateProto.prototype.year = 0;

                /**
                 * LocalDateProto month.
                 * @member {number} month
                 * @memberof space.narwhal.datetime.LocalDateProto
                 * @instance
                 */
                LocalDateProto.prototype.month = 0;

                /**
                 * LocalDateProto date.
                 * @member {number} date
                 * @memberof space.narwhal.datetime.LocalDateProto
                 * @instance
                 */
                LocalDateProto.prototype.date = 0;

                /**
                 * Creates a new LocalDateProto instance using the specified properties.
                 * @function create
                 * @memberof space.narwhal.datetime.LocalDateProto
                 * @static
                 * @param {space.narwhal.datetime.ILocalDateProto=} [properties] Properties to set
                 * @returns {space.narwhal.datetime.LocalDateProto} LocalDateProto instance
                 */
                LocalDateProto.create = function create(properties) {
                    return new LocalDateProto(properties);
                };

                /**
                 * Encodes the specified LocalDateProto message. Does not implicitly {@link space.narwhal.datetime.LocalDateProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.narwhal.datetime.LocalDateProto
                 * @static
                 * @param {space.narwhal.datetime.ILocalDateProto} message LocalDateProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocalDateProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.year != null && Object.hasOwnProperty.call(message, "year"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.year);
                    if (message.month != null && Object.hasOwnProperty.call(message, "month"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.month);
                    if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.date);
                    return writer;
                };

                /**
                 * Encodes the specified LocalDateProto message, length delimited. Does not implicitly {@link space.narwhal.datetime.LocalDateProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.narwhal.datetime.LocalDateProto
                 * @static
                 * @param {space.narwhal.datetime.ILocalDateProto} message LocalDateProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocalDateProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LocalDateProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.narwhal.datetime.LocalDateProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.narwhal.datetime.LocalDateProto} LocalDateProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocalDateProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.narwhal.datetime.LocalDateProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.year = reader.int32();
                                break;
                            }
                        case 2: {
                                message.month = reader.int32();
                                break;
                            }
                        case 3: {
                                message.date = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LocalDateProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.narwhal.datetime.LocalDateProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.narwhal.datetime.LocalDateProto} LocalDateProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocalDateProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LocalDateProto message.
                 * @function verify
                 * @memberof space.narwhal.datetime.LocalDateProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LocalDateProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.year != null && message.hasOwnProperty("year"))
                        if (!$util.isInteger(message.year))
                            return "year: integer expected";
                    if (message.month != null && message.hasOwnProperty("month"))
                        if (!$util.isInteger(message.month))
                            return "month: integer expected";
                    if (message.date != null && message.hasOwnProperty("date"))
                        if (!$util.isInteger(message.date))
                            return "date: integer expected";
                    return null;
                };

                /**
                 * Creates a LocalDateProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.narwhal.datetime.LocalDateProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.narwhal.datetime.LocalDateProto} LocalDateProto
                 */
                LocalDateProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.narwhal.datetime.LocalDateProto)
                        return object;
                    var message = new $root.space.narwhal.datetime.LocalDateProto();
                    if (object.year != null)
                        message.year = object.year | 0;
                    if (object.month != null)
                        message.month = object.month | 0;
                    if (object.date != null)
                        message.date = object.date | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a LocalDateProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.narwhal.datetime.LocalDateProto
                 * @static
                 * @param {space.narwhal.datetime.LocalDateProto} message LocalDateProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocalDateProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.year = 0;
                        object.month = 0;
                        object.date = 0;
                    }
                    if (message.year != null && message.hasOwnProperty("year"))
                        object.year = message.year;
                    if (message.month != null && message.hasOwnProperty("month"))
                        object.month = message.month;
                    if (message.date != null && message.hasOwnProperty("date"))
                        object.date = message.date;
                    return object;
                };

                /**
                 * Converts this LocalDateProto to JSON.
                 * @function toJSON
                 * @memberof space.narwhal.datetime.LocalDateProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocalDateProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LocalDateProto
                 * @function getTypeUrl
                 * @memberof space.narwhal.datetime.LocalDateProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LocalDateProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.narwhal.datetime.LocalDateProto";
                };

                return LocalDateProto;
            })();

            datetime.DateTimeProto = (function() {

                /**
                 * Properties of a DateTimeProto.
                 * @memberof space.narwhal.datetime
                 * @interface IDateTimeProto
                 * @property {number|null} [year] DateTimeProto year
                 * @property {number|null} [month] DateTimeProto month
                 * @property {number|null} [date] DateTimeProto date
                 * @property {number|null} [hour] DateTimeProto hour
                 * @property {number|null} [minute] DateTimeProto minute
                 * @property {string|null} [timeZoneId] DateTimeProto timeZoneId
                 */

                /**
                 * Constructs a new DateTimeProto.
                 * @memberof space.narwhal.datetime
                 * @classdesc Represents a DateTimeProto.
                 * @implements IDateTimeProto
                 * @constructor
                 * @param {space.narwhal.datetime.IDateTimeProto=} [properties] Properties to set
                 */
                function DateTimeProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DateTimeProto year.
                 * @member {number} year
                 * @memberof space.narwhal.datetime.DateTimeProto
                 * @instance
                 */
                DateTimeProto.prototype.year = 0;

                /**
                 * DateTimeProto month.
                 * @member {number} month
                 * @memberof space.narwhal.datetime.DateTimeProto
                 * @instance
                 */
                DateTimeProto.prototype.month = 0;

                /**
                 * DateTimeProto date.
                 * @member {number} date
                 * @memberof space.narwhal.datetime.DateTimeProto
                 * @instance
                 */
                DateTimeProto.prototype.date = 0;

                /**
                 * DateTimeProto hour.
                 * @member {number} hour
                 * @memberof space.narwhal.datetime.DateTimeProto
                 * @instance
                 */
                DateTimeProto.prototype.hour = 0;

                /**
                 * DateTimeProto minute.
                 * @member {number} minute
                 * @memberof space.narwhal.datetime.DateTimeProto
                 * @instance
                 */
                DateTimeProto.prototype.minute = 0;

                /**
                 * DateTimeProto timeZoneId.
                 * @member {string} timeZoneId
                 * @memberof space.narwhal.datetime.DateTimeProto
                 * @instance
                 */
                DateTimeProto.prototype.timeZoneId = "";

                /**
                 * Creates a new DateTimeProto instance using the specified properties.
                 * @function create
                 * @memberof space.narwhal.datetime.DateTimeProto
                 * @static
                 * @param {space.narwhal.datetime.IDateTimeProto=} [properties] Properties to set
                 * @returns {space.narwhal.datetime.DateTimeProto} DateTimeProto instance
                 */
                DateTimeProto.create = function create(properties) {
                    return new DateTimeProto(properties);
                };

                /**
                 * Encodes the specified DateTimeProto message. Does not implicitly {@link space.narwhal.datetime.DateTimeProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.narwhal.datetime.DateTimeProto
                 * @static
                 * @param {space.narwhal.datetime.IDateTimeProto} message DateTimeProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DateTimeProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.year != null && Object.hasOwnProperty.call(message, "year"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.year);
                    if (message.month != null && Object.hasOwnProperty.call(message, "month"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.month);
                    if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.date);
                    if (message.hour != null && Object.hasOwnProperty.call(message, "hour"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.hour);
                    if (message.minute != null && Object.hasOwnProperty.call(message, "minute"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.minute);
                    if (message.timeZoneId != null && Object.hasOwnProperty.call(message, "timeZoneId"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.timeZoneId);
                    return writer;
                };

                /**
                 * Encodes the specified DateTimeProto message, length delimited. Does not implicitly {@link space.narwhal.datetime.DateTimeProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.narwhal.datetime.DateTimeProto
                 * @static
                 * @param {space.narwhal.datetime.IDateTimeProto} message DateTimeProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DateTimeProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a DateTimeProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.narwhal.datetime.DateTimeProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.narwhal.datetime.DateTimeProto} DateTimeProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DateTimeProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.narwhal.datetime.DateTimeProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.year = reader.int32();
                                break;
                            }
                        case 2: {
                                message.month = reader.int32();
                                break;
                            }
                        case 3: {
                                message.date = reader.int32();
                                break;
                            }
                        case 4: {
                                message.hour = reader.int32();
                                break;
                            }
                        case 5: {
                                message.minute = reader.int32();
                                break;
                            }
                        case 6: {
                                message.timeZoneId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a DateTimeProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.narwhal.datetime.DateTimeProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.narwhal.datetime.DateTimeProto} DateTimeProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DateTimeProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a DateTimeProto message.
                 * @function verify
                 * @memberof space.narwhal.datetime.DateTimeProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DateTimeProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.year != null && message.hasOwnProperty("year"))
                        if (!$util.isInteger(message.year))
                            return "year: integer expected";
                    if (message.month != null && message.hasOwnProperty("month"))
                        if (!$util.isInteger(message.month))
                            return "month: integer expected";
                    if (message.date != null && message.hasOwnProperty("date"))
                        if (!$util.isInteger(message.date))
                            return "date: integer expected";
                    if (message.hour != null && message.hasOwnProperty("hour"))
                        if (!$util.isInteger(message.hour))
                            return "hour: integer expected";
                    if (message.minute != null && message.hasOwnProperty("minute"))
                        if (!$util.isInteger(message.minute))
                            return "minute: integer expected";
                    if (message.timeZoneId != null && message.hasOwnProperty("timeZoneId"))
                        if (!$util.isString(message.timeZoneId))
                            return "timeZoneId: string expected";
                    return null;
                };

                /**
                 * Creates a DateTimeProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.narwhal.datetime.DateTimeProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.narwhal.datetime.DateTimeProto} DateTimeProto
                 */
                DateTimeProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.narwhal.datetime.DateTimeProto)
                        return object;
                    var message = new $root.space.narwhal.datetime.DateTimeProto();
                    if (object.year != null)
                        message.year = object.year | 0;
                    if (object.month != null)
                        message.month = object.month | 0;
                    if (object.date != null)
                        message.date = object.date | 0;
                    if (object.hour != null)
                        message.hour = object.hour | 0;
                    if (object.minute != null)
                        message.minute = object.minute | 0;
                    if (object.timeZoneId != null)
                        message.timeZoneId = String(object.timeZoneId);
                    return message;
                };

                /**
                 * Creates a plain object from a DateTimeProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.narwhal.datetime.DateTimeProto
                 * @static
                 * @param {space.narwhal.datetime.DateTimeProto} message DateTimeProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DateTimeProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.year = 0;
                        object.month = 0;
                        object.date = 0;
                        object.hour = 0;
                        object.minute = 0;
                        object.timeZoneId = "";
                    }
                    if (message.year != null && message.hasOwnProperty("year"))
                        object.year = message.year;
                    if (message.month != null && message.hasOwnProperty("month"))
                        object.month = message.month;
                    if (message.date != null && message.hasOwnProperty("date"))
                        object.date = message.date;
                    if (message.hour != null && message.hasOwnProperty("hour"))
                        object.hour = message.hour;
                    if (message.minute != null && message.hasOwnProperty("minute"))
                        object.minute = message.minute;
                    if (message.timeZoneId != null && message.hasOwnProperty("timeZoneId"))
                        object.timeZoneId = message.timeZoneId;
                    return object;
                };

                /**
                 * Converts this DateTimeProto to JSON.
                 * @function toJSON
                 * @memberof space.narwhal.datetime.DateTimeProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DateTimeProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for DateTimeProto
                 * @function getTypeUrl
                 * @memberof space.narwhal.datetime.DateTimeProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DateTimeProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.narwhal.datetime.DateTimeProto";
                };

                return DateTimeProto;
            })();

            return datetime;
        })();

        return narwhal;
    })();

    return space;
})();

module.exports = $root;
