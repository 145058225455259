import {GetTokenSilentlyOptions} from "@auth0/auth0-spa-js";

const SendUnauthenticatedRpc = (method: string, request: Uint8Array): Promise<Uint8Array> => {

  return new Promise((resolve, reject) => {
    InternalSendRpc(null, method, request)
        .then(response => {
          resolve(response);
        })
        .catch(reason => {
          reject(reason);
        });
  });
}

const SendRpc = (tokenFetcher: (options?: GetTokenSilentlyOptions) => Promise<string>,
                 method: string, request: Uint8Array): Promise<Uint8Array> => {

  return new Promise((resolve, reject) => {

    tokenFetcher({
      authorizationParams: {
        audience: 'https://narwhal.kenko.space/'
      }
    }).then(token => {

      InternalSendRpc(token, method, request)
          .then(response => {
            resolve(response);
          })
          .catch(reason => {
            reject(reason);
          });
    }).catch(e => {
      reject(e);
    })
  });
}

const InternalSendRpc = (token: string | null, method: string, request: Uint8Array): Promise<Uint8Array> => {
  return new Promise((resolve, reject) => {

    console.log("[SendRpc] sending " + method + " request");

    // let url = `https://server-2kqynrbjga-uc.a.run.app/${method}`
    let url = `/action/${method}`

    fetch(url, {
      headers: {
        'Authentication': token ?? ''
      },
      method: 'POST',
      body: request
    })
        .then(response => {
          console.log('[SendRpc] received ' + method + ' response with status ' + response.status)
          if (response.status != 200) {
            reject('Status code ' + response.status);
            return;
          }
          
          response.arrayBuffer()
              .then(buffer => {
                resolve(new Uint8Array(buffer));
              })
              .catch(error => {
                console.warn("[SendRpc] error reading response body" + method, error);
                reject();
              });
        })
        .catch(e => {
          console.warn('[SendRpc] error in http layer ' + method, e);
          reject();
        });
  });
};

export {SendRpc, SendUnauthenticatedRpc};
