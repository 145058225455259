import React from "react";
import {useNavigate} from "react-router-dom";

export const ProviderSearch = () => {

  const navigate = useNavigate();
  
  const navigateToProviderSearch = () => {
    let lat = 35.2271;
    let lng = -80.8431;
    navigate(`/search?lat=${lat}&lng=${lng}`)
  }

  return (
      <div style={{
        flex: 1,
        minHeight: 300,
        minWidth: 500,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        marginTop: 20,
      }}>

        Find a provider near Charlotte!!
        <button style={{width: 100}} onClick={() => navigateToProviderSearch()}>Go!</button>

      </div>
  )
}