/* global google */

import React, {useContext} from 'react';
import './App.css';
import {Route} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {Header} from "./Header";
import SearchResults from "./Home/SearchResults";
import {Routes} from "react-router";
import {ProviderSearch} from "./Home/ProviderSearch";
import {UserProfileContext} from "../UserProfileProvider";
import {BookingHome} from "./Home/BookingHome";

/**
 * This is the parent of everything on the site. It acts as a login gate
 * so we only allow people in the whitelist. If the user is not logged
 * in they are prompted to log in.
 *
 * If they're not in the whitelist they are just not permitted.
 *
 * If they're in, then the rest of the app renders.
 */
export const App = () => {

  const {isLoading, user, loginWithRedirect} = useAuth0();

  const profile = useContext(UserProfileContext);

  function auth0Login() {
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname
      },
      authorizationParams: {
        audience: `https://narwhal.kenko.space/`,
        scope: 'openid offline_access' // to get refresh token
      }
    });
  }

  return (
      <div className="App">
        <Header/>
        <div className='Body'>

          <div className={'HeadlineText'}>
            your space for holistic health
          </div>
        </div>

        <div style={{
          flex: 1,
          minHeight: 300,
          minWidth: 500,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 10,
          marginTop: 20,
        }}>

          {isLoading && <div>Authenticating...</div>}

          {!isLoading && !user &&
              <div>
                Kenko is currently in private Alpha. Please sign in to access Kenko.
                <div style={{margin: 10}}>
                  <button onClick={auth0Login}>Sign in</button>
                </div>
              </div>
          }

          {user && profile.isLoading && <div>Loading profile...</div>}

          {profile.profileError && <div style={{color: 'red'}}>Error loading your profile: {profile.profileError}</div>}

          {user && profile.profile && !profile.profile.isWhitelisted &&
              <div>
                We're sorry but it appears you don't have early access.
              </div>
          }

          {user && profile.profile && profile.profile.isWhitelisted && <Routes>
            <Route path="/" element={<ProviderSearch/>}/>
            <Route path={'/search'} element={<SearchResults/>}/>
            <Route path={'/p/:providerShortUrl'} element={<BookingHome/>}/>
            <Route path="*" element={<div>404 Page Not found</div>}/>
          </Routes>}

        </div>

        <div className={'Footer}'}>
          copyright 2024 kenko
          <br/>
          <a href={window.location.protocol + '//provider.' + window.location.host}>provider login</a>
        </div>
      </div>
  );
}

export default App;
