/* global google */

import React, {useEffect, useState} from 'react';
import {SendRpc, SendUnauthenticatedRpc} from "../../rpcSender";
import {FindProviderRequest, FindProviderResponse} from "../../compiled";
import {AdvancedMarker, Map, Pin, useApiIsLoaded} from '@vis.gl/react-google-maps';
import {Link, useSearchParams} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";


/**
 * @constructor
 */
function SearchResults() {

  const {getAccessTokenSilently} = useAuth0();
  const [searchResults, setSearchResults] = useState<FindProviderResponse | null>();
  const [searchResultsLoading, setSearchResultsLoading] = useState(true);
  const [rpcError, setRpcError] = useState('');

  const [params] = useSearchParams();
  let lat = params.get('lat');
  let lng = params.get('lng');

  const apiLoaded = useApiIsLoaded();


  const sendSearchRpc = (lat: number, lng: number) => {

    setSearchResultsLoading(true);
    let request = FindProviderRequest.encode(new FindProviderRequest({
      lat: lat,
      lng: lng,
    })).finish();

    setRpcError('');
    setSearchResults(null)
    setSearchResultsLoading(true);

    SendRpc(getAccessTokenSilently, "find_provider", request)
        .then(r => {
          let response = FindProviderResponse.decode(r);
          setSearchResults(response);
        })
        .catch(e => {
          setRpcError('Error searching, so sorry! ' + e)
        })
        .finally(() => {
          setSearchResultsLoading(false);
        })
  }

  useEffect(() => {

    if (!lat || !lng) {
      setRpcError('bad params')
      return;
    }

    sendSearchRpc(parseFloat(lat), parseFloat(lng));

  }, [lat, lng])

  // Highly important!! "window.google" and "wgoogle.maps" are not necessarily defined before this
  if (!apiLoaded) {
    return <div>loading</div>
  }


  // Good tutorial here:
  // https://developers.google.com/codelabs/maps-platform/maps-platform-101-react-js
  type Poi = { key: string, location: google.maps.LatLngLiteral };

  const pois: Poi[] | undefined = searchResults?.providers?.map(provider => {
    return {
      key: provider.providerId as string,
      location: {lat: provider.lat as number, lng: provider.lng as number}
    };
  })

  if (!window.google) {
    return (<>no googs</>);
  }

  const bounds = new google.maps.LatLngBounds();
  searchResults?.providers.forEach(provider => {
    let location: google.maps.LatLngLiteral = {lat: provider.lat as number, lng: provider.lng as number};
    bounds.extend(location);
  })

  const PoiMarkers = () => {
    return (
        <>
          {pois?.map((poi: Poi) => (
              <AdvancedMarker
                  key={poi.key}
                  position={poi.location}>
                <Pin background={'gray'} glyphColor={'#fff'} borderColor={'black'}/>
              </AdvancedMarker>
          ))}
        </>
    );
  };

  if (rpcError) {
    return (<div style={{color: 'red'}}>Error loading results: {rpcError}</div>);
  }

  if (searchResultsLoading) {
    return <div>Loading...</div>
  }
  
  if (!searchResults) {
    return <div>No search results</div>
  }

  return (
      <div style={{display: 'flex', flexDirection: 'row', flex: 1, width: '100%'}}>

        {/*left side list*/}
        <div style={{display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'start'}}>
          <span>Here are your results!</span>

          {searchResults.providers.map((provider, i) => {
            return <Link to={`/p/${provider.shortUrl}`}>
              <div style={{
                margin: 5,
                padding: 5,
                width: '90%',
                backgroundColor: '#eee',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                textAlign: 'start',
                color: 'black',
                textDecoration: 'underline',
                textDecorationColor: 'black',
              }}>
                <div><b>{provider.name}</b></div>
                <div>{provider.address}</div>
              </div>
            </Link>
          })}

          {searchResults.providers.length == 0 &&
              <div>No providers found, so sorry!</div>
          }

        </div>

        {/*right side map view*/}
        <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
          <Map
              mapId={'f59161af6029d466'}
              style={{width: '100%', height: '100%'}}
              defaultCenter={{lat: 37, lng: -99}}
              defaultZoom={3}
              defaultBounds={bounds.toJSON()}
              maxZoom={15}
              gestureHandling={'greedy'}
              disableDefaultUI={true}>
            <PoiMarkers/>
          </Map>
        </div>

      </div>);
}

export default SearchResults;
