import {useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {SendRpc, SendUnauthenticatedRpc} from "../../rpcSender";
import {
  GetAvailableAppointmentsRequest,
  GetAvailableAppointmentsResponse,
  GetProviderProfileRequest,
  GetProviderProfileResponse,
  IProviderProto
} from "../../compiled";
import {UserProfileContext} from "../../UserProfileProvider";
import {useAuth0} from "@auth0/auth0-react";

export const BookingHome = () => {

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const [provider, setProvider] = useState<IProviderProto | null>()

  const [appointments, setAppointments] = useState<GetAvailableAppointmentsResponse | null>()
  const [appointmentsError, setAppointmentsError] = useState<string>()

  const {getAccessTokenSilently} = useAuth0();
  let {providerShortUrl} = useParams();

  useEffect(() => {

    setLoading(true);
    SendRpc(getAccessTokenSilently, 'get_provider_profile',
        GetProviderProfileRequest.encode(new GetProviderProfileRequest({
          shortUrl: providerShortUrl
        })).finish())
        .then(responseBytes => {
          let response = GetProviderProfileResponse.decode(responseBytes);
          if (!response.okay) {
            setError("Error loading this provider")
            return;
          }
          setProvider(response.provider);
        })
        .catch(e => {
          setError(e);
        })
        .finally(() => {
          setLoading(false);
        });

    SendUnauthenticatedRpc('get_available_appointments',
        GetAvailableAppointmentsRequest.encode(new GetAvailableAppointmentsRequest({
          providerId: providerShortUrl,
          from: {
            year: 2024,
            month: 10,
            date: 1
          },
          to: {
            year: 2024,
            month: 10,
            date: 12
          },
          sku: "skuey",
          timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone
        }))
            .finish())
        .then(responseBytes => {
          let response = GetAvailableAppointmentsResponse.decode(responseBytes);
          if (!response.okay) {
            setAppointmentsError("Error loading appointments")
            return;
          }
          setAppointments(response);
        })
        .catch(e => {
          console.log('appointments error', e)
          setAppointmentsError('Error loading appointments');
        })
        .finally(() => {
          // setLoading(false);
        });
  }, [])

  if (loading) {
    return <div>loading...</div>
  }

  if (error) {
    return <div>Error loading provider! {error}</div>
  }

  return (
      <div className={'Body'} style={{gap: 20, alignItems: 'center'}}>

        <span>The home page of {provider?.name}</span>

        <span>Office location: {provider?.address}</span>

        <span>My services:</span>

        {!provider?.services && <>No services found, uh-oh</>}

        {provider?.services?.map(service => {
          return (<div>
                <b>{service.name}</b> ({service.lengthMinutes} minutes)
                <br/>
                {service.description}
                <br/>
                <b>{service.currencyCode} {service.price}</b>
              </div>
          )
        })}

        <button style={{width: 200}}>Book me!</button>
      </div>);
}
